import React from 'react';
import AlertMessage from 'components/utils/AlertMessage';
import AnswerLabel from './labels/AnswerLabel';
import { DANGERMSGALERT } from 'components/utils/constants';
import { AIAnswerIcon } from './icons/AIAnswerIcon';
import { CatalogIcon } from './icons/CatalogIcon';
import { SendAnswerIcon } from './icons/SendAnswerIcon';

function QuestionCard({
  item,
  loadingSearchLabels,
  errorSearchLabels,
  labelError,
  labels,
  handlerLabelAnswer,
  userEnteredAnswers,
  handleAnswerChange,
  showAnswerQuestionButton,
  sendAnswerEnabled,
  answerWithAI,
  answerWithCatalog,
  handleAnswerQuestion
}) {
  return (
    <div className='rounded-xl flex flex-col h-full p-6 overflow-hidden shadow-lg bg-white border border-gray-200'>
      <div className='flex flex-grow'>
        <div className='w-1/4'>
          <a href={item?.permalink} target='_blank' rel='noreferrer'>
            <img
              className='w-24 h-24 shadow-sm border border-gray-200 rounded-md'
              src={item?.itemThumbnail}
              alt='img' />
          </a>
        </div>
        <div className='w-3/4 pl-[5px] pt-[5px]'>
          <div className='text-sm font-bold'>{item.itemName}</div>
          <div className='justify-between mr-14 mt-6 grid grid-cols-3 gap-4'>
            <div className='flex'>
              <span
                className={`px-4 py-2 rounded-full ${item.statusItem == 'active'
                  ? 'bg-green-800 text-white'
                  : item.statusItem == 'paused'
                    ? 'bg-orange-200 text-orange-700'
                    : item.statusItem == 'closed' &&
                    'bg-red-200 text-red-700'} text-xs font-bold`}
              >
                {item.statusItem[0].toUpperCase() +
                  item.statusItem.slice(1)}
              </span>
            </div>
            <div className='flex p-1'>
              <span className='text-sm'>{item.itemCurrency}</span>
              <span className='text-sm ml-1'>${new Intl.NumberFormat('es-UY').format(item.itemPrice)}</span>
            </div>
            <div className='flex p-1 text-primary-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-5 h-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418' />
              </svg>

              <span className='text-sm ml-1'>
                <a
                  href={`https://amazon.com/dp/${item.skuItem}`}
                  target='_blank' rel="noreferrer"
                >
                  {item.skuItem}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='py-4'>
        <div className='w-full text-primary-700 font-bold flex text-sm '>
          <span className='ml-1'>{item.clientName}</span>
        </div>
        <div className='w-full p-1 text-sm'>
          {item.text}
        </div>
        {loadingSearchLabels && item.status === 'UNANSWERED' && (
          <span>cargando...</span>
        )}
        {errorSearchLabels && item.status === 'UNANSWERED' && (
          <AlertMessage
            message={labelError}
            title='Error: '
            type={DANGERMSGALERT} />
        )}
        {labels.length > 0 && item.status === 'UNANSWERED' && (
          <div key={item.id}>
            <AnswerLabel
              labels={labels}
              handlerLabelAnswer={handlerLabelAnswer}
              elementId={item.id} />
          </div>
        )}
        {(item.status === 'UNANSWERED' && (
          <textarea
            name='textareaAnswer'
            id='textareaAnswer'
            cols='30'
            rows='5'
            className={`p-2 border-2 rounded-md border-gray-300 focus:outline-none focus:border-gray-300 w-full resize-none`}
            value={userEnteredAnswers[item.id]}
            onChange={(event) => handleAnswerChange(item.id, event.target.value)}
          ></textarea>
        )) || (
            <div className='h-auto w-full p-0.5 rounded-t-lg border-2 border-primary-100 text-primary-200 font-bold flex text-sm '>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5' />
                </svg>
              </span>
              <span className='ml-2'>{item.answer}</span>
            </div>
          )}
      </div>
      {item.status === 'UNANSWERED' && showAnswerQuestionButton && (
        <div className='justify-between flex'>
          <div className='flex items-center space-x-4'>
            <button
              className={`bg-primary-50 text-primary-700 border-2 border-primary-200 font-bold rounded-md px-4 py-2 text-sm ${sendAnswerEnabled ? 'cursor-pointer' : 'cursor-normal'}`}
              onClick={() => answerWithAI(item)}
            >
              <div className='flex items-center'>
                <AIAnswerIcon /><span className="ml-2">Respuesta IA</span>
              </div>
            </button>
            <button
              className={`bg-primary-50 text-primary-700 border-2 border-primary-200 font-bold rounded-md px-4 py-2 text-sm ${sendAnswerEnabled ? 'cursor-pointer' : 'cursor-normal'}`}
              onClick={() => answerWithCatalog(item)}
            >
              <div className='flex items-center'>
                <CatalogIcon /><span className="ml-2">Catalogo</span>
              </div>
            </button>
          </div>
          <button
            className={`bg-primary-700 text-white font-bold rounded-md px-4 py-2 text-sm ${sendAnswerEnabled ? 'cursor-pointer' : 'cursor-normal'}`}
            onClick={() => sendAnswerEnabled && item.statusItem == 'active' && handleAnswerQuestion(item.id)}
          >
            <div className='flex items-center'>
              <SendAnswerIcon /><span className="ml-2">Responder</span>
            </div>
          </button>
        </div>
      )}
    </div>
  );
}

export default QuestionCard;