import { Provider } from 'components/AnswerSection/addProductOnDemand/Provider';
import { useEffect, useState } from 'react';

const { useQuery, useManualQuery } = require('graphql-hooks');

const SearchProductAlternatives = `
query SearchProductAlternatives($productTitle: String!, $question: String!) {
    searchProductAlternatives(productTitle: $productTitle, question: $question) {
        searchText
        products {
            id
            catalogProductId
            domainId
            name
            price
            images
            provider
        },
        error
    }
}
`;

export const useSearchProductAlternatives = (productTitle: string, question: string) => {
    const { loading, error, data } = useQuery(SearchProductAlternatives, {
        variables: {
            productTitle,
            question
        }
    });
    return { loading, error, data };
}

export interface ProductAlternativeDTO {
    id: string,
    catalogProductId: string,
    name: string,
    price: number,
    images: Array<string>,
    provider: Provider
}



export interface SearchProductAlternativesResponseDTO {
    searchProductAlternatives: Function;
    clear: Function;
    loading: boolean;
    error: any;
    data?: SearchProductAlternativesResponseDTO;
}

interface SearchProductAlternativesResponseDataDTO {
    searchText: string;
    products: Array<ProductAlternativeDTO>
}

export const useSearchProductAlternativesManual = (): SearchProductAlternativesResponseDTO => {
    const [loadedData, setLoadedData] = useState<SearchProductAlternativesResponseDTO>();
    const [searchProductAlternatives, { loading, error, data }] = useManualQuery(SearchProductAlternatives);
    const clear = () => setLoadedData(undefined);
    useEffect(() => {
        setLoadedData(data);
    }, [data]);
    return { searchProductAlternatives, clear, loading, error, data: loadedData };
}
