import React, { useEffect, useState } from 'react';
import { FORM_FIELD_LIST } from './FormFields/FormFieldList';
import { MLFormFieldProps, ML_FORM_TYPES } from './types/MLForm.types';

function MLFormField({
  controlType,
  label,
  attributes,
  config,
  opts,
  component,
  value,
  itemId
}: MLFormFieldProps) {
  const [newValue, setNewValue] = useState<any>(value);

  useEffect(() => {
    if (controlType === 'NUMBER_UNIT_INPUT' && value) {
      setNewValue(value.replace(itemId ? component.attributes[0].default_unit_id : component.attributes.default_unit_id, '').trim());
    }
  }, []);

  // get 'form field type' name that should be rendered
  const mLFormType: ML_FORM_TYPES =
    ML_FORM_TYPES[controlType as keyof typeof ML_FORM_TYPES];

  if (!mLFormType) {
    console.log(
      '>> mLFormType not found: ',
      controlType,
      ' - dataComp: ',
      component
    );
    return null;
  }

  // get 'form component' that should be rendered
  const FormField: any = FORM_FIELD_LIST[mLFormType];
  return (
    <>
      <FormField
        key={`${attributes.id}-${controlType}`}
        label={label}
        opts={opts}
        config={config}
        attributes={attributes}
        component={component}
        // value={value}
        value={newValue}
      />
    </>
  );
}

export { MLFormField };
