import React, { useState, useEffect, useRef } from 'react';
import { QuestionsFilterTabs } from './QuestionsFilterTabs';
import { SearchBox } from './SearchBox';
import { useSearchQuestionsManual } from '../hooks/useSearchQuestions';
import { useBottomReached } from '../hooks/useBottomReached';
import _ from 'lodash';
import { useAnswerQuestionManual } from '../hooks/useAnswerQuestion';
import { useSearchMlCatalogProductsManual } from 'hooks/useSearchMlCatalogProducts';
import { useSearchCatalogProductsManual } from 'hooks/useSearchCatalogProducts';

//section mention
import AlertMessage from './utils/AlertMessage';
import { WARNINGMSGALERT, DANGERMSGALERT } from './utils/constants';
import { MentionProducts, AnswerSection } from './AnswerSection';
import { AddManualProduct } from './addProduct/manual/AddManualProduct';
import { AddCatalogProduct } from './addProduct/catalog/AddCatalogProduct';

//section label
import { useSearchLabelsManual } from 'hooks/useSearchLabels';
import { useNavigate } from 'react-router-dom';

//delete section
import { DeleteQuestion } from './AnswerSection/deleteModule';

//user permission
import { useSearchPermissionManual } from 'hooks/useSearchPermissions';
import { useDeleteQuestionManual } from 'hooks/useDeleteQuestion';

//module permission
import { flags } from './utils/flags';
import { PowerIcon, SettingsIcon } from './utils/iconComponents';

const MessagesPage = () => {
  const navigate = useNavigate();
  const [loadedMessages, setLoadedMessages] = useState([]);
  // const [unifiedMessages, setUnifiedMessages] = useState([]);
  const [activeFilter, setActiveFilter] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [newQuestions, setNewQuestions] = useState(0);
  const audioEl = useRef(null);
  const [errorInfo, setErrorInfo] = useState({
    message: '',
    statusText: '',
  });

  const [notifications, setNotifications] = useState(0);
  const { searchQuestions, loading, error } = useSearchQuestionsManual();
  const bottomOfScreenReached = useBottomReached();
  const [userEnteredAnswers, setUserEnteredAnswers] = useState({});

  const {
    answerQuestion,
    loading: loadingAnswerQuestion,
    error: errorAnswerQuestion,
  } = useAnswerQuestionManual();
  const { deleteQuestion } = useDeleteQuestionManual();

  const {
    searchMlCatalogProducts,
    loading: loadingMlCatalogProduct,
    error: errorMlCatalogProduct,
  } = useSearchMlCatalogProductsManual();

  const {
    loadSearchCatalogProducts,
    loadingSearchCatalogProducts,
    searchCatalogProductsError,
  } = useSearchCatalogProductsManual();

  //mention module
  const [showWindow, setShowWindow] = useState(false);
  const [answerQuestionId, setAsnwerQuestionId] = useState('');
  const [visibleAddManualProduct, setVisibleAddManualProduct] = useState(false);

  const [visibleAddCatalogProduct, setVisibleAddCatalogProduct] =
    useState(false);
  const [productNameValue, setProductNameValue] = useState('');
  const [productData, setProductData] = useState({});

  const [labels, setLabels] = useState([]);
  const [labelError, setLabelError] = useState();

  //permission module
  const [showAnswerQuestionButton, setShowAnswerQuestionButton] =
    useState(false);
  const [showMessagePage, setShowMessagePage] = useState(false);

  //delete question module
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState();

  const {
    searchLabels,
    loading: loadingSearchLabels,
    error: errorSearchLabels,
  } = useSearchLabelsManual();

  const {
    searchPermission,
    error: errorSearchPermission,
    loading: loadingSearchPermission,
  } = useSearchPermissionManual();

  useEffect(() => {
    const fetchUserPermission = async () => {
      const { data } = await searchPermission();
      if (errorSearchPermission) {
        if (errorSearchPermission.httpError) {
          console.log(
            `${errorSearchPermission.httpError.statusText} - para obtener los permisos`
          );
        }
      }

      if (data) {
        const { isAdmin, lastActiveStore } = data.searchPermission;

        if (isAdmin) {
          setShowAnswerQuestionButton(true);
          setShowMessagePage(true);
        } else if (lastActiveStore.permissions.length > 0) {
          lastActiveStore.permissions.includes('message_writing') &&
            setShowAnswerQuestionButton(true);
          lastActiveStore.permissions.includes('message_reading') &&
            setShowMessagePage(true);
        }
      }
    };
    fetchUserPermission();
  }, []);

  useEffect(() => {
    const fetchDataLabels = async () => {
      const { data } = await searchLabels({
        variables: {
          query: '',
          module: 'mensajes',
          limit: 20,
          offset: 0,
        },
      });

      if (errorSearchLabels) {
        let error = 'Error';
        if (errorSearchLabels.httpError) {
          error = `${errorSearchLabels.httpError.statusText} - para obtener las etiquetas`;
        }

        setLabelError(error);
      }

      if (data) {
        setLabels(data.searchLabels.labels);
      }
    };
    fetchDataLabels();
  }, []);

  useEffect(() => {
    setNotifications(0);
  }, []);

  useEffect(() => {
    const messageHandler = (message) => {
      setNewQuestions(newQuestions + 1);

      const audioCtx = new AudioContext();

      fetch('notification.wav')
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioCtx.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          const source = audioCtx.createBufferSource();
          source.buffer = audioBuffer;
          source.connect(audioCtx.destination);
          source.start();
        });
    };
  }, []);

  useEffect(() => {
    async function queryMessages() {
      if (bottomOfScreenReached) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        searchQuestions({
          variables: {
            query: searchText,
            status: activeFilter === 0 ? 'unanswered' : 'answered',
            offset: loadedMessages.length,
            limit: 20,
          },
        }).then(({ data }) => {
          const isDifferent = _.differenceBy(
            loadedMessages,
            data?.searchQuestions?.questions || [],
            'id'
          );

          if (isDifferent.length > 0) {
            const dataFetched = [
              ...loadedMessages,
              ...(data?.searchQuestions?.questions || []),
            ];

            let uniqueArrayObject = _.uniqBy(dataFetched, (item) => item.id);
            setLoadedMessages(uniqueArrayObject);
          }
        });
      }
    }
    queryMessages();
  }, [bottomOfScreenReached, activeFilter, loadedMessages, searchQuestions]);

  useEffect(() => {
    setLoadedMessages([]);
    searchQuestions({
      variables: {
        query: searchText,
        status: activeFilter === 0 ? 'unanswered' : 'answered',
        offset: 0,
        limit: 20,
      },
    }).then(({ data }) => {
      // setLoadedMessages([...loadedMessages, ...data.searchQuestions.questions]);
      if (data) {
        setLoadedMessages(data.searchQuestions.questions);
      }
    });
  }, [searchText, activeFilter, searchQuestions]);

  const handleTextChange = (value) => setSearchText(value);

  const handlerLabelAnswer = (label, questionId) => {
    setUserEnteredAnswers({
      ...userEnteredAnswers,
      [questionId]: label,
    });
  };

  useEffect(() => {
    if (error) {
      let errorMessage = '';
      let errorStatusText = '';
      // console.log('error searchQuestion', error);
      if (error.httpError !== undefined) {
        const httpErrorMessageConverted = JSON.parse(error.httpError.body);
        errorMessage = httpErrorMessageConverted.errors[0].message;
        errorStatusText = error.graphQLErrors[0].message;
      } else if (error.graphQLErrors !== undefined) {
        errorMessage = error.graphQLErrors[0].message;
        errorStatusText = errorMessage;
      }

      setErrorInfo({
        message: errorMessage,
        statusText: errorStatusText,
      });
    }
  }, [error]);

  const handlerShowWindow = (value) => {
    setShowWindow(value);
  };

  const handlerShowAddManualProduct = (value) => {
    setVisibleAddManualProduct(value);
  };

  const handlerShowAddCatalogProduct = (value) => {
    setVisibleAddCatalogProduct(value);
  };

  const handlerClickAjustes = () => {
    navigate('/etiquetas');
  };

  const handlerDeleteQuestion = (questionId) => {
    setShowDeleteQuestionModal(true);
    setSelectedQuestionId(questionId);
  };

  const deleteQuestionSelected = async () => {
    const result = await deleteQuestion({
      variables: {
        questionId: selectedQuestionId,
      },
    });

    console.log('response delete', result);

    // remove question from loadedMessages
    setLoadedMessages(
      loadedMessages.filter((question) => question.id !== selectedQuestionId)
    );
  };

  return (
      <div className='font-sans mt-4'>
        {/* {showMessagePage && (
          <div className='absolute right-1 top-1 pt-4 mr-4'>
            <span className='bg-primary-100 border border-primary-100 hover:border-primary-500 text-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md transition ease-in-out duration-150 cursor-pointer mr-2'>
              Activar
            </span>
            <span
              className='bg-gray-200 border border-gray-200 hover:border-gray-500 text-gray-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md transition ease-in-out duration-150 cursor-pointer'
              onClick={handlerClickAjustes}
            >
              Ajustes
            </span>
          </div>
        )} */}
        <audio ref={audioEl} src='notification.wav' />
        <div className='font-sans mt-4 relative'>
          {flags.showModuleHeader && (
            <div className='font-semibold text-3xl mb-3'>
              Respuestas automáticas
            </div>
          )}
          {flags.showModuleHeader && (
            <div className='font-normal text-xl mb-6 text-gray-600'>
              Deja que nuestro robot con IA responda las preguntas de manera
              automática
            </div>
          )}
          {!showMessagePage && !loadingSearchPermission && (
            <div className='w-9/12'>
              <AlertMessage
                message='No tiene permisos para ver esta p&aacute;gina!'
                title='Atencion'
                type={DANGERMSGALERT}
              />
            </div>
          )}
        </div>
        {showMessagePage && (
          <>
            <div className='w-full h-[60px] justify-between grid grid-cols-2'>
              <div>
                <QuestionsFilterTabs
                  onTabSelected={setActiveFilter}
                  newQuestions={notifications}
                />
              </div>
              <div className='flex justify-end'>
                <div className='w-[45px] h-[45px] rounded-xl p-2 text-primary-500 hover:text-primary-700 cursor-pointer'>
                  <PowerIcon />
                </div>
                <div className='w-[45px] h-[45px] rounded-xl p-2 text-gray-500 hover:text-gray-700 cursor-pointer' onClick={handlerClickAjustes}>
                  <SettingsIcon />
                </div>
                <div className='shrink basis-0 self-stretch flex-col justify-center items-end gap-3 flex inline-flex mb-2.5'>
                  <div className='w-80 h-11 flex-col justify-start items-start gap-1.5 flex'>
                    <div className='self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex'>
                      <div className='grow shrink basis-0 h-6 justify-start items-center gap-2 flex'>
                        <div className="text-gray-500 text-base font-normal font-['Inter'] leading-normal">
                          <SearchBox
                            query={searchText}
                            onQueryChanged={handleTextChange}
                            placeholder='Buscar'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {flags.showModuleHeader && (
              <div className='font-semibold text-xl mt-8 mb-3'>
                {(activeFilter === 0 && 'Preguntas sin responder') ||
                  (activeFilter === 1 && 'Preguntas respondidas') ||
                  (activeFilter === 2 && 'Historial de preguntas')}
              </div>
            )}
            {showDeleteQuestionModal && (
              <DeleteQuestion
                setShowDeleteQuestionModal={setShowDeleteQuestionModal}
                deleteQuestionSelected={deleteQuestionSelected}
              />
            )}
            <div 
              style={{ height: 'calc(100vh - 130px)' }} >
              <div
                className='flex mb-4 grid lg:grid-cols-3 md:grid-cols-2 gap-4'
              >
                {loadedMessages?.map((item, index) => {
                  return (
                    <AnswerSection
                      key={item.id}
                      item={item}
                      index={index}
                      userEnteredAnswers={userEnteredAnswers}
                      loadingSearchLabels={loadingSearchLabels}
                      errorSearchLabels={errorSearchLabels}
                      labelError={labelError}
                      labels={labels}
                      handlerLabelAnswer={handlerLabelAnswer}
                      showAnswerQuestionButton={showAnswerQuestionButton}
                      handlerDeleteQuestion={handlerDeleteQuestion}
                      setAsnwerQuestionId={setAsnwerQuestionId}
                      handlerShowWindow={handlerShowWindow}
                      setUserEnteredAnswers={setUserEnteredAnswers}
                      loadedMessages={loadedMessages}
                      setLoadedMessages={setLoadedMessages}
                    />
                  );
                })}
                {loading && (
                  <div className='mt-4 ml-4'>
                    <div className='float-left'>
                      <svg
                        className='animate-spin -ml-1 mr-3 h-5 w-5 text-primary-700'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                      >
                        <circle
                          className='opacity-25'
                          cx='12'
                          cy='12'
                          r='10'
                          stroke='currentColor'
                          strokeWidth='4'
                        ></circle>
                        <path
                          className='opacity-75'
                          fill='currentColor'
                          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                        ></path>
                      </svg>
                    </div>
                    <div>Cargando...</div>
                  </div>
                )}
                {error && (
                  <AlertMessage
                    message={errorInfo.statusText + ' ' + errorInfo.message}
                    title='Atencion!'
                    type={WARNINGMSGALERT}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
  );
};

export default MessagesPage;
