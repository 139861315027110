import { useManualQuery } from 'graphql-hooks';

export const GET_STORE_SALES_STATS_QUERY = `
query GetStoreSalesStats($period: String!) {
  getStoreSalesStats(period: $period) {
    time
    catalogListing
    totalQuantity
  }
}
`;

export const useGetStoreSalesStatsManual = () => {
  const [getStoreSalesStats, { loading, error, data }] = useManualQuery(GET_STORE_SALES_STATS_QUERY);

  return {
    getStoreSalesStats,
    loading,
    error,
    data,
  }
}
