export const MlItemsTabs = ({ onTabClick, selectedTab }) => {
  return (
    <div className='w-full h-[40px] flex gap gap-4'>
      <div className='h-[36px] rounded-md content-center'>
        <span className='px-[12px] py-[8px] text-sm font-semibold  text-primary-700'>
          Administrar art&iacute;culos
        </span>
      </div>
      <div
        className='h-[36px] rounded-md content-center cursor-pointer'
        onClick={() => onTabClick(1)}
      >
        <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
          Art&iacute;culos elegibles
        </span>
      </div>
      <div
        className='h-[36px] rounded-md content-center cursor-pointer'
        onClick={() => onTabClick(2)}
      >
        <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
          Estrategia de optimizaci&oacute;n
        </span>
      </div>
      <div
        className='h-[36px] rounded-md content-center cursor-pointer'
        onClick={() => onTabClick(3)}
      >
        <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
          Todos los art&iacute;culos
        </span>
      </div>
    </div>
  );
};
