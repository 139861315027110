import React, { useEffect, useState } from 'react';
import { useSearchAllProductsManual } from 'hooks/useSearchAllProducts';
import { useSearchItemByAsinManual } from 'hooks/useSearchItemByAsin';
import { useBottomReached } from '../hooks/useBottomReached';
import _ from 'lodash';
import { SearchBox } from './SearchBox';
import ProductAllListItem from './ProductAllListItem';
import ArticleIcon from './utils/iconComponents/ArticleIcon';
import { SpinnerIcon } from './MlCatalogProductList/SpinnerIcon';
import { ExclamationIcon } from './MlCatalogProductList/ExclamationIcon';
import { useDeleteItemManual } from 'hooks/useDeleteItem';
import { useUpdateArticleStatusManual } from 'hooks/useUpdateArticleStatus';

export const ProductsAllList = ({ query: queryStr, setSelectedTabNumber }) => {
  const [query, setQuery] = useState(queryStr);
  const { searchAllProducts, loading, error, products, total } =
    useSearchAllProductsManual();
  const { searchItemByAsin, loading: loadingItemByAsin } =
    useSearchItemByAsinManual();
  const [page, setPage] = useState(0);
  const [loadedProducts, setLoadedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const bottomOfScreenReached = useBottomReached();
  const pageSize = 20;
  const lastPage = Math.floor(total / pageSize);
  const [reachedTable, setReachedTable] = useState(false);

  const {
    deleteItem,
    loading: loadingDeleteItem,
    error: errorDeleteItem,
  } = useDeleteItemManual();

  const { updateArticleStatus, loading: loadingUpdate } =
    useUpdateArticleStatusManual();

  useEffect(() => {
    setPage(0);
    setLoadedProducts([]);

    const getProductByAsin = async () => {
      const dataProductByAsin = await searchItemByAsin({
        variables: {
          asin: query,
        },
      });

      setLoadedProducts(dataProductByAsin.data.searchItemByAsin?.products);
    };

    if (query != '') {
      if (/^B[0-9,A-Z]{9}$/.test(query)) {
        getProductByAsin();
        return;
      }

      const newQuery = /^MLU[0-9,A-Z]{4,9}$/.test(query)
        ? query.replace('MLU', '')
        : query;
      searchAllProducts({
        variables: {
          query: newQuery,
          offset: page * pageSize,
          limit: pageSize,
        },
      });
    }
  }, [query]);

  useEffect(() => {
    searchAllProducts({
      variables: {
        query,
        offset: page * pageSize,
        limit: pageSize,
      },
    });
  }, [page]);

  useEffect(() => {
    if (products) {
      setLoadedProducts((loadedProducts) => loadedProducts.concat(products));
    }
  }, [products]);

  useEffect(() => {
    if (
      (bottomOfScreenReached || reachedTable) &&
      products &&
      !loading &&
      !loadingItemByAsin &&
      page < lastPage
    ) {
      setReachedTable(false);
      setPage(page + 1);
    }
  }, [bottomOfScreenReached, reachedTable]);

  const onProductSelected = (product) => {
    console.info('product...', product);
    // setSelectedProduct(product);
  };

  const handlerScroll = (e) => {
    const target = e.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setReachedTable(true);
    }
  };

  const deleteItemById = async (item, idx) => {
    const confirm = window.confirm(
      'Esta seguro que desea eliminar este registro?'
    );

    if (confirm) {
      const statusResponse = await updateArticleStatus({
        variables: {
          item,
          status: 'delete',
        },
      });

      if (statusResponse?.data?.updateArticleStatus?.status) {
        products.splice(idx, 1);
        setLoadedProducts([...products]);
      }
    }
  };

  const onUpdateStatusHandler = async (status, item, idx) => {
    console.info('info...', status, item, idx);
    const statusResponse = await updateArticleStatus({
      variables: {
        item,
        status,
      },
    });

    if (statusResponse?.data?.updateArticleStatus?.status) {
      loadedProducts[idx].status =
        statusResponse?.data?.updateArticleStatus?.status;
      setLoadedProducts([...loadedProducts]);
    }
  };

  return (
    <div>
      <div className='w-full h-[60px] flex justify-between mb-2'>
        <div className='w-[3%] h-[60px] flex justify-start p-2 items-center'>
          <ArticleIcon />
        </div>
        <div className='w-[57%] h-[60px] flex justify-start p-[10px]'>
          <div className='w-full h-[40px] flex gap gap-4'>
            <div
              className='h-[36px] rounded-md content-center cursor-pointer'
              onClick={() => setSelectedTabNumber(0)}
            >
              <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
                Administrar art&iacute;culos
              </span>
            </div>
            <div
              className='h-[36px] rounded-md content-center cursor-pointer'
              onClick={() => setSelectedTabNumber(1)}
            >
              <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
                Art&iacute;culos elegibles
              </span>
            </div>
            <div
              className='h-[36px] rounded-md content-center cursor-pointer'
              onClick={() => setSelectedTabNumber(2)}
            >
              <span className='px-[12px] py-[8px] text-sm font-semibold text-gray-500'>
                Estrategia de optimizaci&oacute;n
              </span>
            </div>
            <div className='h-[36px] rounded-md content-center'>
              <span className='px-[12px] py-[8px] text-sm font-semibold text-primary-700 '>
                Todos los art&iacute;culos
              </span>
            </div>
          </div>
        </div>
        <div className='w-[40%] h-[60px] flex justify-end'>
          <div className='grow shrink basis-0 self-stretch flex-col justify-center items-end gap-3 inline-flex'>
            <div className='w-80 h-11 flex-col justify-start items-start gap-1.5 flex'>
              <div className='h-[44px] self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex'>
                <div className='grow shrink basis-0 h-6 justify-start items-center gap-2 flex'>
                  <div className="text-gray-500 text-base font-normal font-['Inter'] leading-normal"></div>
                  <SearchBox
                    query={query}
                    onQueryChanged={_.debounce(setQuery, 1000)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full self-stretch h-11 px-6 py-3 bg-gray-50 shadow border border-b-0 border-gray-200 justify-start items-center gap-3 inline-flex rounded-t-xl'>
        <div className='w-full justify-start items-center gap-1 flex'>
          <div className="w-[8%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Imagen
          </div>
          <div className="w-[42%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Titulo
          </div>
          <div className="w-[11%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Precio
          </div>
          <div className="w-[11%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Disponibles
          </div>
          <div className="w-[4%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Vendidos
          </div>
          <div className="w-[12%] flex justify-center text-slate-600 text-xs font-medium font-['Inter'] leading-[18px] text-gray-600">
            Estado
          </div>
          <div className="w-[10%] text-slate-600 text-xs font-medium font-['Inter'] leading-[18px]"></div>
        </div>
      </div>
      <div
        className='w-full flex-col justify-start items-start gap-6 inline-flex'
        style={{ height: 'calc(100vh - 130px)' }}
      >
        <div className='self-stretch h-[833px] bg-white flex-col justify-start items-start flex rounded-t-0 rounded-b-xl'>
          <div
            className='w-full h-full bg-white rounded-t-0 rounded-b-xl shadow border border-gray-200 flex-col justify-start items-start flex overflow-y-auto'
            onScroll={handlerScroll}
          >
            <div className='self-stretch bg-white justify-start items-start inline-flex'>
              <div
                className='w-full flex-col justify-start items-start inline-flex'
                style={{ height: 'calc(100vh - 130px)' }}
              >
                {loadedProducts.map((product, key) => (
                  <ProductAllListItem
                    product={product}
                    idx={key}
                    onProductSelected={onProductSelected}
                    loadedProducts={loadedProducts}
                    setLoadedProducts={setLoadedProducts}
                    deleteItemById={deleteItemById}
                    onUpdateStatusHandler={onUpdateStatusHandler}
                  />
                ))}
                {(loading || loadingItemByAsin) && (
                  <div className='ml-4 mt-4'>
                    <SpinnerIcon />
                  </div>
                )}
                {error && (
                  <div className='ml-4 mt-4'>
                    <ExclamationIcon message={error.httpError?.statusText} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
