import React, { useEffect, useState } from 'react';
import { usePublishProductManual } from 'hooks/usePublishProductManual';
import AddManualProductForm from './AddManualProductForm';
import {
  AddCatalogProductResultError,
  AddCatalogProductResultLoading,
  AddCatalogProductResultSuccess,
} from '../common/AddCatalogProductResult';
import { PRODUCT_FORM_STATES } from '../types/AddCatalogProduct.types';

function AddManualProduct({
  visible,
  onCancel,
  onPublish,
  closeButtonLabel = 'Enviar link',
  asin = '',
  titleAsinProduct = '',
  productDescriptionAsin = '',
  productImages = [],
  productName = '',
  categoryPath = [],
  attributeValues = [],
  price = 0,
  currency = 'UYU',
  formTitle = 'Publicar nuevo producto',
  buttonName = 'Publicar',
  onSubmit,
  currentFormState = PRODUCT_FORM_STATES.SHOW_SUCCESS,
  publishProductResponse=[],
  showCreationProductResult=false,
  loadingFetch=false,
  soldQuantity=0,
  itemId=''
}: {
  visible: boolean;
  onCancel: () => void;
  onPublish: (permalink: string) => void;
  closeButtonLabel?: string;
  asin?: string;
  titleAsinProduct: string;
  productDescriptionAsin: string;
  productImages: any;
  productName?: string;
  categoryPath?: string[];
  attributeValues?: { name: string; value: string | number }[];
  price?: number;
  currency?: string;
  formTitle?: string;
  buttonName?: string;
  onSubmit: (payload: any) => void;
  currentFormState?: string;
  publishProductResponse?: any;
  showCreationProductResult?:boolean;
  loadingFetch?:boolean;
  soldQuantity?:number;
  itemId?: string;
}) {

  const [selectedImages, setSelectedImages] = useState<any>(productImages.filter((img: any) => {
    const [width, height] = img.variant.split('x');
    return !img?.link?.includes('-F.jpg') || (width >= 500 && height >= 500);
  }).filter((img: any, i: number, arr: any[]) => {
    // const imgId = img?.link?.split('_NP_')[1].split('_')[0];
    const imgId = (img?.link?.split('_NP_')[1] !== undefined) ? img?.link?.split('_NP_')[1]?.split('_')[0] : img?.link?.split('D_')[1]?.split('_')[0]; //TODO verificar cuando no venga de amazon
    const idCount = arr.filter((img: any) => img.link.includes(imgId)).length;
    return idCount >= 1;
  }));


  const handleCancel = () => {
    if (onCancel) onCancel();
  };


  return !visible ? (
    <></>
  ) : (
    <div className='sm:p-3'>
      <div className='fixed top-0 left-0 lg:w-screen lg:h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm'>
        <div className='w-screen flex justify-center max-h-screen overflow-y-auto'>
          {
            <div className='max-w-screen-xl w-full'>
              <div className='bg-white shadow-md rounded px-8 pt-12 pb-8 mb-4'>
                {currentFormState == PRODUCT_FORM_STATES.SUBMIT_LOADING && (
                  <AddCatalogProductResultLoading />
                )}
                {currentFormState == PRODUCT_FORM_STATES.SUBMIT_ERROR && (
                  <AddCatalogProductResultError />
                )}
                {currentFormState == PRODUCT_FORM_STATES.SUBMIT_SUCCESS && (
                  <AddCatalogProductResultSuccess
                    handleCloseOkSuccess={() => {
                      onPublish(publishProductResponse.publishManualProduct.id);
                    }}
                    addCatalogProductInfo={String(showCreationProductResult)}
                    closeButtonLabel={closeButtonLabel}
                  />
                )}
                {currentFormState == PRODUCT_FORM_STATES.SHOW_SUCCESS && (
                  <AddManualProductForm
                    asin={asin}
                    handleCancel={handleCancel}
                    productName={productName}
                    price={price ?? 0}
                    currency={currency}
                    titleAsinProduct={
                      titleAsinProduct?.replace(
                        /[^a-zA-Z0-9 ñÑáéíóúÁÉÍÓÚüÜ,.]/g,
                        ''
                      ) ?? ''
                    }
                    productDescriptionAsin={productDescriptionAsin}
                    productImages={selectedImages}
                    onProductImagesChanged={(images: any) => {
                      setSelectedImages(images);
                    }}
                    categoryPath={categoryPath ?? []}
                    attributeValues={attributeValues}
                    formTitle={formTitle}
                    buttonName={buttonName}
                    onSubmit={onSubmit}
                    loadingFetch={loadingFetch}
                    soldQuantity={soldQuantity}
                    itemId={itemId}
                  />
                )}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export { AddManualProduct };
