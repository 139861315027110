import React, { Fragment } from 'react';

function AnswerLabel({ labels, handlerLabelAnswer, elementId }) {
  const handlerPressTitle = (pressed) => {
    handlerLabelAnswer(pressed, elementId);
  };

  let labelInfo = '';

  if (labels && labels.length > 0) {
    labelInfo = labels.map((label) => (
      <span
        key={label.id}
        className={`inline-block py-1 px-4 m-1 rounded-full text-primary-700 font-bold text-xs border border-primary-100 hover:border-primary-500 cursor-pointer bg-primary-100`}
        onClick={() => handlerPressTitle(label.description)}
      >
        {label.title}
      </span>
    ));
  }

  return (
    <div className='py-4 w-full flex flex-wrap'>
      {labelInfo}
    </div>
  );
}

export default AnswerLabel;
