import React from 'react';

export const AIAnswerIcon = () => <div>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7942_7394)">
      <path d="M3.74996 18.3332V14.1665M3.74996 5.83317V1.6665M1.66663 3.74984H5.83329M1.66663 16.2498H5.83329M10.8333 2.49984L9.38814 6.25722C9.15313 6.86825 9.03563 7.17376 8.8529 7.43074C8.69095 7.6585 8.49196 7.8575 8.2642 8.01945C8.00722 8.20218 7.7017 8.31968 7.09068 8.55469L3.33329 9.99984L7.09068 11.445C7.7017 11.68 8.00722 11.7975 8.2642 11.9802C8.49196 12.1422 8.69095 12.3412 8.8529 12.5689C9.03563 12.8259 9.15314 13.1314 9.38815 13.7425L10.8333 17.4998L12.2784 13.7425C12.5135 13.1314 12.631 12.8259 12.8137 12.5689C12.9756 12.3412 13.1746 12.1422 13.4024 11.9802C13.6594 11.7975 13.9649 11.68 14.5759 11.445L18.3333 9.99984L14.5759 8.55469C13.9649 8.31968 13.6594 8.20217 13.4024 8.01945C13.1746 7.8575 12.9756 7.6585 12.8137 7.43074C12.631 7.17376 12.5135 6.86825 12.2784 6.25722L10.8333 2.49984Z" stroke="#6941C6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_7942_7394">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>;
