import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

export const ItemsInfractionsChart = (props: {}) => {
	const chartRef = useRef(null);
	const chartInstance = useRef(null);
	const [option, setOption] = useState({
		title: {
			text: 'Correcciones de infracciones',
			left: 'center',
			top: 20
		},
		tooltip: {
			trigger: 'item'
		},
		legend: {
			bottom: 0,
			right: 'center'
		},
		series: [
			{
				name: 'Proceso de corrección de infracciones',
				type: 'pie',
				radius: ['70%', '40%'],
				avoidLabelOverlap: true,
				itemStyle: {
					borderRadius: 10,
					borderColor: '#f3f4f6',
					borderWidth: 2,
				},
				label: {
					show: false,
				},
				labelLine: {
					show: false
				},
				data: [
					{ value: 84, name: `Sin infracciones`, itemStyle: { color: '#6941C6' } },
					{ value: 6, name: 'Infracciones de calidad', itemStyle: { color: '#9E77ED' } },
					{ value: 10, name: 'Infracciones de contenido', itemStyle: { color: '#C6B9E8' } }
				]
			}
		]
	});

	useEffect(() => {
		if (chartRef.current && !chartInstance.current) {
			// @ts-ignore
			chartInstance.current = echarts.init(chartRef.current);

			// @ts-ignore
			chartInstance.current.setOption(option);
		}
	}, []);

	return <div><div ref={chartRef} style={{ width: 320, height: 390 }} /></div>;
}
