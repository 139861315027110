import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchCancelledOrderManual } from 'hooks/useSearchCancelledOrders';
import AntDesignAmazonCircleFilled from './iconComponent/AmazonIconComponent';
import ArcticonsMercadoLibre from './iconComponent/MercadoLibreComponent';
import { SettingsIcon } from 'components/utils/iconComponents';

const OrderDetail = ({ orderInfo }) => {
  const [precio, setPrecio] = useState();
  const [dateCreated, setDateCreated] = useState();
  const [checkStock, setCheckStock] = useState(false);
  const navigate = useNavigate();
  const { searchCancelledOrders } = useSearchCancelledOrderManual();

  useEffect(() => {
    if (orderInfo) {
      setCheckStock(false);
      setPrecio(orderInfo.currency_id + '' + orderInfo.unit_price);
      const d = new Date(orderInfo.dateCreated);
      const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
      const month = d.getMonth() + 1;
      const year = d.getFullYear();
      const date = `${day}/${month}/${year}`;
      setDateCreated(date);

      async function getCancelledOrder() {
        const data = await searchCancelledOrders({
          variables: {
            item: orderInfo.id,
          },
        });

        if (data.data.searchCancelledOrders.orders.length > 0) {
          setCheckStock(true);
        }
      }

      getCancelledOrder();
    }
  }, [orderInfo]);

  const handlerClickAjustes = () => {
    navigate('/etiquetas');
  };

  return (
        <div className='w-full h-24 bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start flex overflow-y-auto'>
          <div className='inline-flex p-4 ext-sm font-normal font-["Inter"] leading-tight'>
            <div className='mr-4 text-gray-500'>
              Enlace ML:{' '}
              <span className='text-primary-700'>
                <a href={orderInfo?.item?.permalink} target='_blank'>
                  {orderInfo?.id}
                </a>
              </span>
            </div>
            <div className='mr-4 text-gray-500'>
              Enlace Proveedor:{' '}
              <span
                className={`${
                  checkStock ? 'text-orange-400' : 'text-primary-700'
                } inline-flex`}
                title={`${checkStock ? 'Verificar producto en stock' : ''}`}
              >
                <a
                  href={orderInfo?.item?.amazonUrl}
                  target='_blank'
                  className='mr-1'
                >
                  {checkStock && '!'} {orderInfo?.item?.seller_custom_field}
                </a>
                {checkStock && (
                  <>
                    <a href={`https://www.amazon.com/gp/your-account/order-history/?search=${orderInfo?.item?.seller_custom_field}`} target='_blank'><AntDesignAmazonCircleFilled /></a>
                    <a href={`https://www.mercadolibre.com.uy/ventas/omni/listado?search=${orderInfo?.item?.seller_custom_field}`} target='_blank'><ArcticonsMercadoLibre /></a>
                  </>
                )}
              </span>
            </div>
          </div>
          <div className='inline-flex pt-0 pl-4 pr-4 pb-4 ext-sm font-normal font-["Inter"] leading-tight'>
            <div className='mr-4 text-gray-500'>
              Precio: <span className='text-primary-700'>{precio}</span>
            </div>
            <div className='mr-4 text-gray-500'>
              Fecha: <span className='text-primary-700'>{dateCreated}</span>
            </div>
            <div className='mr-4 text-gray-500'>
              Pack:{' '}
              <span className='text-primary-700'>{orderInfo?.order_id}</span>
            </div>
          </div>
        </div>
  );
};

export default OrderDetail;
