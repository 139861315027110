import { useQuery, useManualQuery } from 'graphql-hooks';

const SearchAllProductsQuery = `
  query SearchAllProducts($query: String!, $offset: Int, $limit: Int) {
    searchAllProducts (query: $query, offset: $offset, limit: $limit) {
        products {
            id
            title
            image
            price
            available_quantity
            sold_quantity
            currency_id
            status
            start_time
            permalink
            seller_custom_field
            descriptions
            pictures{
                link
                variant
                width
                height
                id
            }
            asinUrl
            attributes {
                name
                value
            }
            categoryPath
            catalog_listing
          }
          offset
          total
    }
  }
`;

const useSearchAllProductsManual = () => {
  const [searchAllProducts, { loading, error, data }] = useManualQuery(
    SearchAllProductsQuery
  );
  const { products, offset, total } = data
    ? data.searchAllProducts
    : { products: [], offset: 0, total: 0 };
  return { searchAllProducts, loading, error, products, offset, total };
};

export { useSearchAllProductsManual };
