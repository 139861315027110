import { useRef, useState, useEffect } from 'react';
import { useBidOnProductManual } from '../hooks/useBidOnProduct';
import TwoShapeIcon from './utils/iconComponents/TwoShapeIcon';
import NewModalHeader from './newModalParts/NewModalHeader';
import NewModalPerfil from './newModalParts/NewModalPerfil';
import NewModalPrices from './newModalParts/NewModalPrices';
import NewModalAlwaysWin from './newModalParts/NewModalAlwaysWin';
import NewModalShipping from './newModalParts/NewModalShipping';
import NewModalShippingOptionList from './newModalParts/NewModalShippingOptionList';
import NewModalCompetitors from './newModalParts/NewModalCompetitors';
import { useGetMlItemByIdManual } from '../hooks/useGetMlItemById';
import { useChangeShippingOptionManual } from 'hooks/useChangeShippingOption';
import { useGetShippingPreferencesManual } from 'hooks/useGetShippingPreferences';

export const BidOnProductNewModal = ({
  product,
  currentMin,
  currentMax,
  onBid,
  onCancel,
}) => {
  const { bidOnProduct, data, loading, error } = useBidOnProductManual();
  const {
    getMlItemById,
    data: itemData,
    loading: itemLoading,
    error: itemError,
  } = useGetMlItemByIdManual();
  const {
    getShippingPreferences,
    data: shippingPreferencesData,
    loading: shippingPreferencesLoading,
    error: shippingPreferencesError,
  } = useGetShippingPreferencesManual();
  const [isFlexEnabled, setIsFlexEnabled] = useState(false);
  const [isFreeShippingMandatory, setIsFreeShippingMandatory] = useState(false);
  const [shippingData, setShippingData] = useState([]);
  const [minPrice, setMinPrice] = useState(
    product.currentBid ? product.currentBid.minPrice : product.price
  );
  const [maxPrice, setMaxPrice] = useState(
    product.currentBid ? product.currentBid.maxPrice : product.price
  );
  const {
    changeShippingOption,
    data: changeShippingOptionData,
    loading: changeShippingOptionLoading,
    error: changeShippingOptionError,
  } = useChangeShippingOptionManual();

  const timer = useRef(null);

  const increment = (add) => {
    timer.current = setInterval(
      () =>
        add
          ? setMaxPrice((prev) => +prev + 1)
          : setMinPrice((prev) => +prev + 1),
      130
    );
  };

  const decrement = (add) => {
    timer.current = setInterval(
      () =>
        add ? setMaxPrice((prev) => prev - 1) : setMinPrice((prev) => prev - 1),
      130
    );
  };

  function timeoutClear() {
    clearInterval(timer.current);
  }

  const handleClickBackground = (e) => {
    typeof onCancel === 'function' && onCancel();
    e.stopPropagation();
  };

  const handleBidOnProduct = async () => {
    if (!product.permalink.includes('/p/')) return; // TODO: show error
    const bid = await bidOnProduct({
      variables: {
        productId: product.id,
        catalogProductId: product.permalink.split('/p/')[1],
        minPrice: parseFloat(minPrice),
        maxPrice: parseFloat(maxPrice),
        currency: product.currency_id,
        active: true,
      },
    });
    await changeShippingOption({
      variables: {
        productId: product.id,
        shippingOptionId: [
          'PAID_BY_SELLER',
          'PAID_BY_BUYER',
          'FLEX_PAID_BY_SELLER',
          'FLEX_PAID_BY_BUYER',
          'CUSTOM',
          'NOT_SPECIFIED',
        ][setlectedTypeShipping.id - 1],
      },
    });
    // console.log('bid product en modal',bid);
    typeof onBid === 'function' && onBid(bid);
  };

  //new added
  useEffect(() => {
    setShippingData([
      {
        id: 1,
        icon: <TwoShapeIcon />,
        shippingType: 'Mercado envios',
        shippingOwner: 'envio a tu cargo',
        enabled: true,
      },
      {
        id: 2,
        icon: <TwoShapeIcon />,
        shippingType: 'Mercado envios',
        shippingOwner: 'envio a cargo del comprador',
        enabled: !isFreeShippingMandatory,
      },
      {
        id: 3,
        icon: <TwoShapeIcon />,
        shippingType: 'Mercado envio flex',
        shippingOwner: 'envio a tu cargo',
        enabled: isFlexEnabled,
      },
      {
        id: 4,
        icon: <TwoShapeIcon />,
        shippingType: 'Mercado envios flex',
        shippingOwner: 'envio a cargo del comprador',
        enabled: !isFreeShippingMandatory && isFlexEnabled,
      },
      {
        id: 5,
        icon: <TwoShapeIcon />,
        shippingType: 'Envio a coordinar',
        shippingOwner: '',
        enabled: false,
      },
      {
        id: 6,
        icon: <TwoShapeIcon />,
        shippingType: 'No especificado',
        shippingOwner: '',
        enabled: false,
      },
    ]);
  }, [isFlexEnabled, isFreeShippingMandatory]);

  const [isVisible, setIsVisible] = useState(false);
  const [setlectedTypeShipping, setSelectedTypeShipping] = useState({});
  const [listShippingType, setListShippingType] = useState([]);

  const handlerOpenDiv = () => {
    setIsVisible((prevState) => !prevState);
  };

  const handlerSelectedtypeShipping = (selectedShipping) => {
    setIsVisible(false);
    setSelectedTypeShipping(selectedShipping);
  };

  useEffect(() => {
    if (product?.id) {
      getMlItemById({ variables: { id: product?.id } });
    }
  }, [product]);

  useEffect(() => {
    if (itemData?.getMlItemById) {
      getShippingPreferences({
        variables: { categoryId: itemData?.getMlItemById?.category_id },
      });
    }
  }, [itemData]);

  useEffect(() => {
    if (shippingPreferencesData?.getShippingPreferences) {
      setIsFlexEnabled(
        shippingPreferencesData?.getShippingPreferences.logistics?.some(
          (logistic) =>
            logistic.mode === 'me2' && logistic.types.includes('self_service')
        )
      );
    }
  }, [shippingPreferencesData]);

  useEffect(() => {
    if (itemData?.getMlItemById) {
      const item = itemData?.getMlItemById;
      const isMe2 = item.shipping.mode === 'me2';
      const isCustom = item.shipping.mode === 'custom';
      const isNotSpecified = item.shipping.mode === 'not_specified';
      const isFlex = item.shipping.tags.includes('self_service_in');
      const isFreeShipping = item.shipping.free_shipping;
      setSelectedTypeShipping(
        (isMe2 && isFlex && isFreeShipping && shippingData[2]) ||
          (isMe2 && isFlex && !isFreeShipping && shippingData[3]) ||
          (isMe2 && !isFlex && isFreeShipping && shippingData[0]) ||
          (isMe2 && !isFlex && !isFreeShipping && shippingData[1]) ||
          (isCustom && shippingData[4]) ||
          (isNotSpecified && shippingData[5])
      );
    }
    setIsFreeShippingMandatory(
      itemData?.getMlItemById?.shipping?.tags?.includes(
        'mandatory_free_shipping'
      )
    );
  }, [itemData]);

  useEffect(() => {
    setListShippingType(
      shippingData.filter(
        (shipping) => shipping.id !== setlectedTypeShipping.id
      )
    );
  }, [setlectedTypeShipping, shippingData]);

  console.info('product...', product);

  return (
    <div
      className='fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-end items-center backdrop-blur-sm'
      onClick={handleClickBackground}
      aria-hidden="true"
    >
      <div className='p-4' onClick={(e) => e.stopPropagation()} aria-hidden="true">
        <div className='w-[600px] h-screen pt-[26px] pr-[26px] pb-0 pl-[26px] bg-white overflow-y-auto'>
          {/* header */}
          <NewModalHeader handleClickBackground={handleClickBackground} />
          {/* perfil */}
          <NewModalPerfil product={product} />
          {/* prices */}
          <NewModalPrices product={product} />
          {/* cambios para ganar siempre */}
          <NewModalAlwaysWin />
          {/* envio */}
          <NewModalShipping
            handlerOpenDiv={handlerOpenDiv}
            setlectedTypeShipping={setlectedTypeShipping}
          />
          {isVisible && (
            <NewModalShippingOptionList
              listShippingType={listShippingType}
              handlerSelectedtypeShipping={handlerSelectedtypeShipping}
            />
          )}
          {/* competidores */}
          <NewModalCompetitors product={product} />
          {/* botones */}
          <div className='w-full py-4 flex justify-between'>
            <div
              className={`text-black bg-white border border-gray-200 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-[210px] h-[44px] justify-center cursor-pointer`}
              onClick={onCancel}
              aria-hidden='true'
            >
              <div>Cancelar</div>
            </div>
            <div
              className={`text-white bg-primary-700 border border-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-[210px] h-[44px] justify-center cursor-pointer`}
              onClick={handleBidOnProduct}
              aria-hidden='true'
            >
              <div>Guardar</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
