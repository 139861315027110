import DotIcon from 'components/utils/iconComponents/DotIcon';
import TickIcon from 'components/utils/iconComponents/TickIcon';
import React from 'react';

export const ProgressBarNew = ({ progress }: { progress: number }) => {
  const progressBarStyle = {
    width: `${progress}%`,
    background: 'linear-gradient(0deg, #9E77ED, #6941C6, #9E77ED)',
    animation: 'color-change 5s linear infinite',
  };

  return (
    <>
      <div className='w-[80%] shadow-sm h-0.5 bg-primary-200 rounded-md'>
        <div
          className='h-full shadow-md bg-gradient-to-r from-sky-500 to-primary-700 transition-all duration-250 rounded-md'
          style={progressBarStyle}
        ></div>
        <div className='relative top-[-1.2rem] flex justify-between'>
          <span className='w-[40px] h-[40px] rounded-full border border-primary-200 bg-primary-100 p-1 flex justify-center items-center'>
            <TickIcon />
          </span>
          <span
            className={`w-[40px] h-[40px] rounded-full border ${
              progress > 30
                ? 'border-primary-200 bg-primary-100'
                : 'border-gray-200 bg-gray-100'
            }   p-1 flex justify-center items-center`}
          >
            {progress >= 40 ? (
              <TickIcon />
            ) : (
              <span
                className={`${
                  progress > 30 && progress <= 40 && 'animate-ping'
                }`}
              >
                <DotIcon color={`${progress > 30 ? '#7F56D9' : '#d1d5db'}`} />
              </span>
            )}
          </span>
          <span
            className={`w-[40px] h-[40px] rounded-full border ${
              progress > 60
                ? 'border-primary-200 bg-primary-100'
                : 'border-gray-200 bg-gray-100'
            }   p-1 flex justify-center items-center`}
          >
            {progress >= 70 ? (
              <TickIcon />
            ) : (
              <span
                className={`${
                  progress > 60 && progress <= 70 && 'animate-ping'
                }`}
              >
                <DotIcon color={`${progress > 60 ? '#7F56D9' : '#d1d5db'}`} />
              </span>
            )}
          </span>
          <span
            className={`w-[40px] h-[40px] rounded-full border ${
              progress > 93
                ? 'border-primary-200 bg-primary-100'
                : 'border-gray-200 bg-gray-100'
            }   p-1 flex justify-center items-center`}
          >
            {progress > 95 ? (
              <TickIcon />
            ) : (
              <span
                className={`${
                  progress > 93 && progress <= 95 && 'animate-ping'
                }`}
              >
                <DotIcon color={`${progress > 90 ? '#7F56D9' : '#d1d5db'}`} />
              </span>
            )}
          </span>
        </div>
      </div>
      <div className='w-[100%] mt-8 grid grid-cols-4 gap-4'>
        <span className='flex justify-center text-center text-primary-700 font-bold'>
          Buscando producto
        </span>
        <span
          className={`flex justify-center text-center ${
            progress > 30 ? 'text-primary-700' : 'text-gray-700'
          } font-bold`}
        >
          Generando atributos
        </span>
        <span
          className={`flex justify-center text-center ${
            progress > 60 ? 'text-primary-700' : 'text-gray-700'
          } font-bold`}
        >
          Ajustando imagenes
        </span>
        <span
          className={`flex justify-center text-center ${
            progress > 90 ? 'text-primary-700' : 'text-gray-700'
          }  font-bold`}
        >
          Optimizando descripcion
        </span>
      </div>
    </>
  );
};
