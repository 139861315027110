import React from 'react';

const NewModalShippingOptionList = ({
  listShippingType,
  handlerSelectedtypeShipping,
}) => {
  return (
    <div
      className={`w-[546px] h-[300px] overflow-y-auto mt-[2px] absolute z-10 bg-white`}
    >
      {listShippingType.map((shipping) => (
        <div
          className={`w-full mt-[2px] ${shipping.enabled ? "cursor-pointer" : "cursor-not-allowed"} hover:bg-gray-50`}
          key={shipping.id}
          onClick={() => shipping.enabled && handlerSelectedtypeShipping(shipping)}
          aria-hidden = 'true'
        >
          <div className='w-full h-[72px] border-2 border-gray-200 rounded-lg flex p-4'>
            <div className='w-[10%] flex'>
              <span className='flex justify-center items-center bg-primary-100 border-primary-50 rounded-full w-[32px] h-[32px]'>
                {shipping.icon}
              </span>
            </div>
            <div className='w-[70%]'>
              <div className='w-full flex'>
                <span className='w-full text-sm font-medium text-gray-600'>
                  {shipping.shippingType}
                </span>
              </div>
              <div className='w-full flex'>
                <span className='w-full text-sm font-normal text-gray-600'>
                  {shipping.shippingOwner}
                </span>
              </div>
            </div>
            <div className='w-[20%] flex justify-end px-[5px]'>
              <span className='flex justify-center items-center border border-gray-300 rounded-full w-[16px] h-[16px] bg-white'></span>
            </div>
            g         </div>
        </div>
      ))}
    </div>
  );
};

export default NewModalShippingOptionList;
