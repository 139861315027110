import React, { MouseEvent, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { AddManualProductFormAttributes } from './AddManualProductFormAttributes';
import { AddManualProductFormControls } from './AddManualProductFormControls';
import { ImageSelector } from '../../ImageSelector/ImageSelector';
import { ImageListItem } from '../../ImageSelector/ImageSelector.types';
import { AddProductBasicFormValues } from '../types/AddCatalogProduct.types';
import { useSearchAttrCategoryManual } from 'hooks/useSearchAttrCategory';
import { FetchData } from 'graphql-hooks';
import { get, uniq } from 'lodash';
import LoadingIconComponent from 'components/utils/iconComponents/LoadingIconComponent';

const formDefaultValues: AddProductBasicFormValues = {
  productCategory: '',
  productDescription: '',
  productName: '',
  currency: '',
  price: 0,
  stock: 12,
  productImages: [],
  attributes: '',
};

function AddManualProductForm({
  handleCancel,
  asin,
  productName,
  titleAsinProduct,
  price = 0,
  currency,
  productDescriptionAsin,
  productImages,
  onProductImagesChanged,
  categoryPath: initialCategoryPath = [],
  attributeValues = [],
  formTitle,
  buttonName,
  onSubmit,
  loadingFetch,
  soldQuantity = 0,
  itemId = '',
}: {
  handleCancel: (evt: MouseEvent<HTMLButtonElement>) => void;
  asin?: string;
  productName: string;
  titleAsinProduct: string;
  price: number;
  currency: string;
  productDescriptionAsin: string;
  productImages: any;
  onProductImagesChanged: (images: any) => void;
  categoryPath?: string[];
  attributeValues?: { name: string; value: string | number }[];
  formTitle: string;
  buttonName: string;
  onSubmit: (payload: any) => void;
  loadingFetch: boolean;
  soldQuantity?: number;
  itemId?: string;
}) {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formDefaultValues, productName },
  });

  const { searchAttrCategory, loading: loadingAttrCategory } =
    useSearchAttrCategoryManual();

  const [attrSections, setAttrSections] = useState<any[]>(attributeValues); // TODO: Create group[] type
  const [productCategory, setProductCategory] = useState<string | undefined>();
  const [currencyOptions, setCurrencyOptions] = useState<string[]>([]);
  const [updatedImages, setUpdatedImages] = useState<string[]>([]);
  const [preloadedImages, setPreloadedImages] = useState<string[]>([]);
  const [picturesIds, setPicturesIds] = useState<any[]>([]);
  const [categoryPath, setCategoryPath] =
    useState<string[]>(initialCategoryPath);

  const [pimaryTabsSelected, setPrimaryTabsSelected] = useState(true);
  const [secundaryTabsSelected, setOnsecundaryAttribute] = useState(false);
  const [resComponentAttributes, setResComponentAttributes] = useState<any>([]);
  const [savedTabsAttributes, setSavedTabsAttributes] = useState<any>([attributeValues, []]);

  useEffect(() => {
    const arrPicturesIds = [];
    for (let idx = 0; idx < productImages.length; idx++) {
      if (productImages[idx].id !== null) {
        arrPicturesIds.push({ id: productImages[idx].id });
      }
    }

    arrPicturesIds.length > 0 && setPicturesIds(arrPicturesIds);

    const imageGroupsIds = productImages.reduce((acc: any, curr: any) => {
      // const id = curr?.link?.split('NP_')?.[1]?.split('-F.')?.[0];
      const imageTypeFormat = curr?.link.includes('-S.') ? '-S.' : '-F.';
      const id = curr?.link?.split('D_')?.[1]?.split(imageTypeFormat)?.[0];
      if (!acc.includes(id)) {
        acc.push(id);
      }
      return acc;
    }, []);
    const imagesByGroup = imageGroupsIds.map(
      (id: string) =>
        productImages.find((img: any) => img.link.includes(`_${id}-`))?.link
    );
    setPreloadedImages(imagesByGroup || []);
  }, []);

  useEffect(() => {
    if (getValues('productCategory') !== productCategory)
      setValue('productCategory', productCategory ?? '');
    clearErrors();
    //eslint-disable-next-line
  }, [productCategory]);

  useEffect(() => {
    const dataFetched = async (itemId: any) => {
      const { data } = await searchAttrCategory({
        variables: {
          itemId: itemId,
        },
      });
      // setResComponentAttributes(dataRes.data);
      console.info('data....', data);

      if (data) {
        setResComponentAttributes(data.searchAttrCategory);
      }
    };

    if (itemId) {
      dataFetched(itemId);
    }
  }, [itemId]);

  useEffect(() => {
    setValue('productName', titleAsinProduct?.slice(0, 60));
  }, [titleAsinProduct]);

  useEffect(() => {
    setValue('productDescription', productDescriptionAsin);
  }, [productDescriptionAsin]);

  useEffect(() => {
    const tabs1 = resComponentAttributes?.mainAttributes || [];
    const tabs2 = resComponentAttributes?.secundaryAttributes || [];
    setSavedTabsAttributes([tabs1, tabs2]);
  }, [resComponentAttributes]);

  const saveTabs1Attributes = () => {

    const currentTab1Values = resComponentAttributes?.mainAttributes.map(
      (attr: any) => {
        return {
          id: attr.id,
          name: attr.name,
          value: getValues(attr.id)?.value || getValues(attr.id)?.label || getValues(attr.id) || '',
          values: attr.values,
        };
      }
    );

    setSavedTabsAttributes([currentTab1Values, [...savedTabsAttributes[1]]]);
  };

  const saveTabs2Attributes = () => {

    const currentTab2Values = resComponentAttributes?.secundaryAttributes.map(
      (attr: any) => {
        return {
          id: attr.id,
          name: attr.name,
          value: getValues(attr.id)?.value || getValues(attr.id)?.label || getValues(attr.id) || '',
          values: attr.values,
        };
      }
    );

    setSavedTabsAttributes([[...savedTabsAttributes[0]], currentTab2Values]);
  };

  const onSubmitForm = async (formData: FieldValues) => {
    const {
      currency,
      price,
      productCategory,
      productDescription,
      productName,
      stock,
    } = formData;
    const attributes = Object.keys(formData)
      .filter((key) => key === key.toUpperCase() && formData[key])
      .reduce((acc: any, key) => {
        const attributeValueName = ''; // TODO: assign value
        acc.push({
          id: key,
          value_name: formData[key],
        });
        return acc;
      }, []);

    const pictures = [...updatedImages, ...preloadedImages]
      .filter((picture) => picture?.includes('-F.jpg') || picture?.includes('-S.jpg'))
      .map((url) => ({ source: url }))
      .slice(0, 12);

    try {
      const payload = {
        title: productName,
        currencyId: currency,
        price: parseFloat(price),
        categoryId: productCategory,
        description: productDescription,
        stock: parseInt(stock),
        attributes: attributes.map((attr: any) => ({
          id: attr.id,
          value_name: attr.value_name?.label
            ? attr.value_name.label
            : String(attr.value_name), // TODO: Fix this in the right place
        })),
        pictures,
        sku: asin,
        picturesIds,
      };

      // console.log('---->> onSubmit Manual Product - formData: ', payload);

      // validate images array
      if (!pictures.length) {
        //TODO: Define/show UI error
        throw new Error('Missing images for product with manual publish');
      }

      onSubmit(payload);
    } catch (err) {
      console.error('>> Error onSubmit: ', err);
    }
  };

  const handleImageListChanged = (list: ImageListItem[]) => {
    const images = list.map((item) => item.variations[0].url);
    setUpdatedImages(images);
  };

  const handleRemoveUploadedImage = (imageUrlImage: string) => {
    const firstItem = updatedImages.indexOf(imageUrlImage);
    if (firstItem > -1) {
      const newUpdatesImages = [...updatedImages];
      newUpdatesImages.splice(firstItem, 1);
      setUpdatedImages(newUpdatesImages);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className='text-xl font-bold mb-4 text-center'>
        {formTitle}{' '}
        {asin && (
          <a
            href={`https://www.amazon.com/dp/${asin}`}
            target='_blank'
            rel='noreferrer'
          >
            {asin}
          </a>
        )}
      </div>
      <div className=''>
        <div className='sm:flex justify-between gap-5'>
          <ImageSelector
            onImageListChanged={handleImageListChanged}
            onRemoveUploadedImage={handleRemoveUploadedImage}
            productImages={productImages}
            onProductImagesChanged={(images: any) => {
              const imageGroupsKeys = images.reduce((acc: any, curr: any) => {
                if (!acc.includes(curr.variant)) {
                  acc.push(curr.variant);
                }
                return acc;
              }, []);
              const imagesByGroup = imageGroupsKeys.map(
                (key: string) =>
                  images.find((img: any) => img.variant === key)?.link
              );
              setPreloadedImages(imagesByGroup || []);
            }}
          />
          <AddManualProductFormControls
            register={register}
            setValue={setValue}
            getValues={getValues}
            productName={productName}
            productCategory={productCategory}
            setProductCategory={setProductCategory}
            setCategoryPath={setCategoryPath}
            errors={errors}
            price={price}
            currency={currency}
            currencyOptions={currencyOptions}
            setCurrencyOptions={setCurrencyOptions}
            categoryPath={categoryPath ?? []}
            attributeValues={attributeValues}
            soldQuantity={soldQuantity}
          />
        </div>
        {/* a partir de aqui */}
        <div className='w-full'>
          <div className='h-[45px] flex pt-2 text-primary-700 border-gray-100 border-b-2'>
            <span
              className={`mx-4 cursor-pointer ${pimaryTabsSelected && 'border-primary-700 border-b-2'
                }`}
              onClick={() => {
                saveTabs2Attributes();
                setPrimaryTabsSelected(true);
                setOnsecundaryAttribute(false);
              }}
            >
              Atributos principales
            </span>
            {pimaryTabsSelected && loadingAttrCategory && (
              <LoadingIconComponent />
            )}
            {itemId &&
              resComponentAttributes?.secundaryAttributes?.length > 0 && (
                <span
                  className={`mx-4 cursor-pointer ${secundaryTabsSelected && 'border-primary-700 border-b-2'
                    }`}
                  onClick={() => {
                    saveTabs1Attributes();
                    setPrimaryTabsSelected(false);
                    setOnsecundaryAttribute(true);
                  }}
                >
                  Atributos secundarios
                </span>
              )}
            {secundaryTabsSelected && loadingAttrCategory && (
              <LoadingIconComponent />
            )}
          </div>
          {pimaryTabsSelected && (
            // <></>
            <AddManualProductFormAttributes
              categoryId={productCategory}
              control={control}
              attrSections={itemId ? savedTabsAttributes[0] : attrSections}
              setAttrSections={setAttrSections}
              attributeValues={itemId ? savedTabsAttributes[0] : attributeValues}
              attrComponents={resComponentAttributes?.mainComponentsGroup || []}
              getValues={getValues}
              asin={asin}
              itemId={itemId}
            />
          )}
          {itemId &&
            secundaryTabsSelected &&
            resComponentAttributes?.secundaryAttributes?.length > 0 && (
              // <></>
              <AddManualProductFormAttributes
                categoryId={productCategory}
                control={control}
                attrSections={savedTabsAttributes[1] || []}
                setAttrSections={setAttrSections}
                attributeValues={savedTabsAttributes[1] || []}
                attrComponents={
                  resComponentAttributes?.secundaryComponentsGroup || []
                }
                getValues={getValues}
                asin={asin}
                itemId={itemId}
              />
            )}
        </div>
        {/* hasta aqui... */}
        <div className='flex items-center justify-end gap-3'>
          <button
            type='button'
            className={`text-black bg-white border border-gray-200 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-[210px] h-[44px] justify-center`}
            onClick={handleCancel}
          >
            Cancelar
          </button>
          <button
            className={`text-white bg-primary-700 border border-primary-700 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md  transition ease-in-out duration-150' w-[210px] h-[44px] justify-center ${!productCategory ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            type='submit'
            disabled={!productCategory}
          >
            {loadingFetch ? <LoadingIconComponent /> : buttonName}
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddManualProductForm;
